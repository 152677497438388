import PropTypes from "prop-types";
// import { m } from "framer-motion";

import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListSubheader,
  Paper,
  Popover,
  Popper,
  Stack,
  styled,
} from "@mui/material";
import Iconify from "src/components/Iconify";
import useResponsive from "src/hooks/useResponsive";
import { PATH_PAGE } from "src/routes/paths";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { useQuery } from "react-query";
import { baseGet } from "src/utils/apiClient";
import { formatCategoryLinks, getCategoryLinks } from "src/utils/utils";
import { Masonry } from "@mui/lab";

// ----------------------------------------------------------------------
const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  // marginRight: theme.spacing(5),
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": {
    opacity: 0.48,
    textDecoration: "underline",
  },
}));

const SubLinkStyle = styled((props) => (
  <ListItem sx={{ p: 0 }}>
    <Link target="" rel="noopener" {...props}>
      {props.children}
    </Link>
  </ListItem>
))(({ theme }) => ({
  ...theme.typography.body2,
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(1),
  color: theme.palette.text.secondary,
  transition: theme.transitions.create("color"),
  "&:hover": {
    color: theme.palette.text.primary,
  },
}));
// ----------------------------------------------------------------------
const FEATURED_CATEGORIES = [
  {
    path: PATH_PAGE.eCommerce.category("phones-tablets"),
    title: "Phones & Tablets",
    children: [
      { title: "test", path: "test" },
      { title: "test", path: "test" },
      { title: "test", path: "test" },
      { title: "test", path: "test" },
    ],
  },
  {
    path: PATH_PAGE.eCommerce.category("fashion"),
    title: "Fashion",
    children: [
      { title: "test", path: "test" },
      { title: "test", path: "test" },
      { title: "test", path: "test" },
      { title: "test", path: "test" },
    ],
  },
  {
    path: PATH_PAGE.eCommerce.category("household"),
    title: "Household",
    children: [
      { title: "test", path: "test" },
      { title: "test", path: "test" },
      { title: "test", path: "test" },
      { title: "test", path: "test" },
    ],
  },
  {
    path: PATH_PAGE.eCommerce.category("tvs-audios-and-cameras"),
    title: "Electronics",
    children: [
      { title: "test", path: "test" },
      { title: "test", path: "test" },
      { title: "test", path: "test" },
      { title: "test", path: "test" },
    ],
  },
  {
    path: PATH_PAGE.eCommerce.category("computing-gaming"),
    title: "Computing & Gaming",
    children: [
      { title: "test", path: "test" },
      { title: "test", path: "test" },
      { title: "test", path: "test" },
      { title: "test", path: "test" },
    ],
  },
  {
    path: PATH_PAGE.eCommerce.category("your-kiosk"),
    title: "Your Kiosk ",
    children: [
      { title: "test", path: "test" },
      { title: "test", path: "test" },
      { title: "test", path: "test" },
      { title: "test", path: "test" },
    ],
  },
];

// ----------------------------------------------------------------------

export default function SecondaryMenu({ isMobile = false }) {
  const isDesktop = useResponsive("up", "md");

  const [categories, setCategories] = useState([]);

  useQuery("categories", () => baseGet("/category/all"), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setCategories(getCategoryLinks(formatCategoryLinks(data)));
    },
  });

  const _featured_categories = !isMobile
    ? FEATURED_CATEGORIES
    : FEATURED_CATEGORIES.slice(0, 3);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, 200);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      component="div"
      sx={{
        bgcolor: "primary.dark",
        // px: isMobile ? 2 : 8, py: 1
      }}
    >
      <Stack direction="row" sx={{ alignItems: "center" }} gap={2}>
        {isDesktop && (
          <>
            <Button
              aria-describedby={id}
              variant=""
              startIcon={<Iconify icon={"mage:dashboard-2"} />}
              onClick={handleClick}
              onMouseEnter={handleClick}
              // onMouseLeave={handleClose}
            >
              Categories
            </Button>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              // onMouseLeave={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <CategoryView categories={categories} onClose={handleClose} />
            </Popover>

            <Divider orientation="vertical" color="white" flexItem width={1} />
          </>
        )}

        <FeaturedList featured_list={_featured_categories} />
      </Stack>
    </Box>
  );
}

// ----------------------------------------------------------------------

function FeaturedList({ featured_list }) {
  const [anchorElSec, setAnchorElSec] = useState(null);
  const [activeLink, setActiveLink] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  const handleMouseEnter = (event, items, index) => {
    setAnchorElSec(event.currentTarget);
    setMenuItems(items);
    setActiveLink(index);
  };

  const handleMouseLeave = () => {
    setAnchorElSec(null);
    setMenuItems([]);
    setActiveLink(null);
  };

  // const handleClickSec = (event, index) => {
  //   setAnchorElSec(event.currentTarget);
  //   setActiveLink(index)
  // };

  // const handleCloseSec = () => {
  //   setTimeout(()=> {
  //     setAnchorElSec(null);
  //     setActiveLink(null);
  //   }, 10)
  // };

  const secOpen = Boolean(anchorElSec);

  return (
    <Stack direction="row" gap={2}>
      {featured_list.map((category, index) => (
        <Box
          key={index}
          aria-owns={
            secOpen && activeLink === index ? `popover-${index}` : undefined
          }
          aria-haspopup="true"
          // aria-expanded={secOpen && activeLink === index ? 'true' : undefined}
          onMouseEnter={(e) => handleMouseEnter(e, category.children, index)}
          // onMouseLeave={handleMouseLeave}
          sx={{ cursor: "pointer", position: "relative" }}
        >
          <Link href={category.path} color="primary.lighter">
            {category.title}
          </Link>
        </Box>
      ))}

      <Popper
        anchorEl={anchorElSec}
        open={secOpen}
        placement="bottom-start"
        sx={{ zIndex: 1200 }}
      >
        <Paper sx={{mt:1}}>
          <Stack
            direction="column"
            gap={1}
            sx={{ py: 2, minWidth: 150 }}
            onMouseLeave={handleMouseLeave}
          >
            {menuItems.map((child, index) => (
              <>
                <Link
                  key={index}
                  href={child.path}
                  color="primary.dark"
                  sx={{ px: 2 }}
                  onClick={handleMouseLeave}
                >
                  {child.title}
                </Link>
                <Divider sx={{ width: "100%", height: 2 }} />
              </>
            ))}
          </Stack>
        </Paper>
      </Popper>

    </Stack>
  );
}

// ----------------------------------------------------------------------

function CategoryView({ categories, onClose }) {
  const [selected, setSelected] = useState(null);

  const isActive = (path) => selected?.pathname === path;

  const handleOpen = (val) => {
    setSelected(val);
  };

  const handleClose = () => {
    setSelected(null);
  };

  const open = Boolean(selected);

  return (
    <Box
      component="div"
      sx={{
        height: "76vh",
        overflowY: "hidden",
      }}
      onMouseLeave={onClose}
    >
      <Stack direction="row" spacing={2} sx={{ height: "100%" }}>
        <List
          sx={{
            maxWidth: 200,
            overflowY: "auto",
          }}
        >
          {categories.map((item, index) => (
            <ListItem
              onMouseOver={() => handleOpen(item)}
              // onMouseLeave={handleClose}
            >
              <LinkStyle
                // onClick={() => }
                href={item.path}
                sx={{
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  width: "100%",
                  // bg: 'red',
                  // ...(open && { opacity: 0.48 }),
                }}
              >
                {item.title}
                <Iconify
                  icon={
                    // open
                    //   ? "eva:arrow-ios-back-fill"
                    //   :
                    "eva:arrow-ios-forward-fill"
                  }
                  sx={{ ml: 0.5, width: 16, height: 16 }}
                />
              </LinkStyle>
            </ListItem>
          ))}
        </List>

        <Box
          sx={{
            display: open ? "" : "none",
            py: 2,
            px: 2,
            width: "50vw",
            maxWidth: "60vw",
            overflowY: "auto",
          }}
          onMouseLeave={handleClose}
        >
          <Masonry columns={3} spacing={3}>
            {selected?.children.map((list) => {
              const { title, path, children } = list;

              return (
                <
                  // key={title}
                  // item
                  // xs={12}
                  // md={3}
                >
                  <List disablePadding sx={{ bg: "" }}>
                    <ListSubheader
                      disableSticky
                      disableGutters
                      sx={{
                        display: "flex",
                        lineHeight: "unset",
                        alignItems: "center",
                        color: "text.primary",
                        typography: "overline",
                      }}
                    >
                      {/* <IconBullet type="subheader" />  */}
                      <Link href={path}>{title}</Link>
                    </ListSubheader>

                    {children?.map((item) => (
                      <SubLinkStyle
                        key={item.title}
                        href={item.path}
                        sx={{
                          ...(isActive(item.path) && {
                            color: "text.primary",
                            typography: "subtitle2",
                          }),
                        }}
                      >
                        <>
                          {/* <IconBullet /> */}
                          {item.title}
                        </>
                      </SubLinkStyle>
                    ))}
                  </List>
                </>
              );
            })}
          </Masonry>
        </Box>
      </Stack>
    </Box>
  );
}

// ----------------------------------------------------------------------

MenuDesktopItem.propTypes = {
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.array,
  }),
};

function MenuDesktopItem({ item, isHome, isOffset, onOpen, onClose }) {
  const { pathname } = useLocation();

  // const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { title, path, children } = item;

  const isActive = (path) => pathname === path;

  if (children) {
    return (
      <>
        <LinkStyle
          onClick={handlePopoverOpen}
          onMouseEnter={handlePopoverOpen}
          sx={{
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            ...(isHome && { color: "common.white" }),
            ...(isOffset && { color: "text.primary" }),
            ...(open && { opacity: 0.48 }),
          }}
        >
          {title}
          <Iconify
            icon={
              open ? "eva:arrow-ios-back-fill" : "eva:arrow-ios-forward-fill"
            }
            sx={{ ml: 0.5, width: 16, height: 16 }}
          />
        </LinkStyle>

        <Popover
          open={open}
          // anchorReference="anchorPosition"
          anchorPosition={{ top: 140, left: 280 }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          onClose={handlePopoverClose}
          PaperProps={{
            sx: {
              px: 3,
              pt: 5,
              pb: 3,
              // right: 16,
              // m: "auto",
              borderRadius: 2,
              maxWidth: (theme) => theme.breakpoints.values.lg,
              boxShadow: (theme) => theme.customShadows.z24,
            },
          }}
        >
          <Grid container spacing={3}>
            {children.map((list) => {
              const { title, children } = list;

              return (
                <Grid key={title} item xs={12} md={3}>
                  <List disablePadding>
                    <ListSubheader
                      disableSticky
                      disableGutters
                      sx={{
                        display: "flex",
                        lineHeight: "unset",
                        alignItems: "center",
                        color: "text.primary",
                        typography: "overline",
                      }}
                    >
                      <IconBullet type="subheader" /> {title}
                    </ListSubheader>

                    {children?.map((item) => (
                      <SubLinkStyle
                        key={item.title}
                        href={item.path}
                        sx={{
                          ...(isActive(item.path) && {
                            color: "text.primary",
                            typography: "subtitle2",
                          }),
                        }}
                      >
                        <>
                          <IconBullet />
                          {item.title}
                        </>
                      </SubLinkStyle>
                    ))}
                  </List>
                </Grid>
              );
            })}
          </Grid>
        </Popover>
      </>
    );
  }

  if (title === "Documentation") {
    return (
      <LinkStyle
        href={path}
        target="_blank"
        rel="noopener"
        sx={{
          ...(isHome && { color: "common.white" }),
          ...(isOffset && { color: "text.primary" }),
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  return (
    <LinkStyle
      to={path}
      component={RouterLink}
      end={path === "/"}
      sx={{
        ...(isHome && { color: "common.white" }),
        ...(isOffset && { color: "text.primary" }),
        "&.active": {
          color: "primary.main",
        },
      }}
    >
      {title}
    </LinkStyle>
  );
}

// ----------------------------------------------------------------------

IconBullet.propTypes = {
  type: PropTypes.oneOf(["item", "subheader"]),
};

function IconBullet({ type = "item" }) {
  return (
    <Box sx={{ width: 24, height: 16, display: "flex", alignItems: "center" }}>
      <Box
        component="span"
        sx={{
          ml: "2px",
          width: 4,
          height: 4,
          borderRadius: "50%",
          bgcolor: "currentColor",
          ...(type !== "item" && {
            ml: 0,
            width: 8,
            height: 2,
            borderRadius: 2,
          }),
        }}
      />
    </Box>
  );
}
