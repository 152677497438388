import Fuse from "fuse.js";
import { orderBy } from "lodash";
import moment from "moment/moment";
import { PATH_PAGE } from "src/routes/paths";

const searchOptions = {
  // isCaseSensitive: false,
  includeScore: true,
  shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  minMatchCharLength: 1,
  // location: 0,
  threshold: 0.2,
  // distance: 99999999,
  // useExtendedSearch: false,
  ignoreLocation: true,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ["sku", { name: "name", weight: 0.3 }],
};

export const CARD_LIMITS = [20, 50, 100];

export function applyProductFilter(tableData, filterName) {
  const fuse = new Fuse(tableData, searchOptions);

  if (filterName) {
    const found = fuse.search(filterName);

    tableData = found.map((item) => item.item);
  }

  return tableData;
}

export function convertToUrlSafeWithPlus(query) {
  let encodedQuery = encodeURIComponent(query);
  return encodedQuery.replace(/%20/g, "+");
}

export function decodeUrlSafeWithPlus(encodedQuery) {
  // First replace + with spaces
  let decodedQuery = encodedQuery.replace(/\+/g, " ");
  // Then decode the URI components
  return decodeURIComponent(decodedQuery);
}

export function filterProductByCategory(category, products) {
  if (category) {
    return products.filter((item) => item.master_category_id === category);
  } else {
    return products;
  }
}

export function formatDate(date) {
  if (date) {
    return moment(date).format("DD MMM YYYY");
  } else {
    return date;
  }
}

export function truncateText(str, n) {
  if (str) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  } else {
    return str;
  }
}

export const getCategoryLinks = (categories) => {
  const categoryDict = categories.reduce((acc, category) => {
    (acc[category.parent_id] = acc[category.parent_id] || []).push(category);
    return acc;
  }, {});

  function buildHierarchy(parent_id = 0) {
    if (!categoryDict[parent_id]) {
      return [];
    }

    return categoryDict[parent_id].map((category) => {
      const children = buildHierarchy(category.id);
      return children.length > 0 ? { ...category, children } : { ...category };
    });
  }

  return buildHierarchy(0);
};

export const formatCategoryLinks = (categories) => {
  const _newLinks = [];

  categories.forEach((item) => {
    _newLinks.push({
      id: item.id,
      title: item.name,
      path: PATH_PAGE.eCommerce.category(item.slug),
      parent_id: item.parent_id,
    });
  });

  return _newLinks;
};

export function applyProductsCardFilter(products, sortBy, filters) {
  // SORT BY
  // if (sortBy === "none") {
  //   products = orderBy
  // }
  if (sortBy === "featured") {
    products = orderBy(products, ["sold"], ["desc"]);
  }
  if (sortBy === "newest") {
    products = orderBy(products, ["createdAt"], ["desc"]);
  }
  if (sortBy === "priceDesc") {
    products = orderBy(products, ["offer_price"], ["desc"]);
  }
  if (sortBy === "priceAsc") {
    products = orderBy(products, ["offer_price"], ["asc"]);
  }
  // FILTER PRODUCTS
  // if (filters.gender.length > 0) {
  //   products = products.filter((product) => filters.gender.includes(product.gender));
  // }
  if (filters.category !== "All") {
    products = products.filter(
      (product) => product.master_category_id === parseInt(filters.category)
    );
  }
  if (filters.brand !== null) {
    console.log('filter-brand', filters.brand)
    products = products.filter(
      (product) => parseInt(product.brand_id) === parseInt(filters.brand)
    );
  }
  // if (filters.colors.length > 0) {
  //   products = products.filter((product) => product.colors.some((color) => filters.colors.includes(color)));
  // }

  const min = filters.priceRange[0];
  const max = filters.priceRange[1];

  if (min !== 0 || max !== 500000) {
    products = products.filter(
      (product) => product.mrp >= min && product.mrp <= max
    );
  }

  if (filters.rating) {
    products = products.filter((product) => {
      const convertRating = (value) => {
        if (value === "up4Star") return 4;
        if (value === "up3Star") return 3;
        if (value === "up2Star") return 2;
        return 1;
      };
      return product.totalRating > convertRating(filters.rating);
    });
  }
  return products;
}

export function getProductPrice(mrp, offer_price) {
  if (offer_price !== null && offer_price !== 0) {
    return offer_price;
  } else {
    return mrp;
  }
}

export function checkIfWishlist(wishlist, item) {
  if (wishlist && item) {
    return wishlist.some((obj) => obj.product_id === item.product_id || obj.product_id === item.id);
  } else {
    return false;
  }
};

export function findWishlistItem(wishlist, id) {
  if(wishlist && id){
    return wishlist.find(item => item.product_id === id || item.id === id);
  } else {
    return null
  }
}

export function sortBrandsByCategory(brands, category) {
  if (brands && category) {
    const _cleanBrands = brands.filter(item => item.category_ids != null);

    return _cleanBrands.filter(obj => obj.category_ids.split(',').includes(String(category?.id)))
  } else {
    return [];
  }
}

export function jsonToFormData(json) {
  const formData = new FormData();

  for (const key in json) {
      if (json.hasOwnProperty(key)) {
          const value = json[key];
          
          if (Array.isArray(value)) {
              // If the value is an array, append each item separately
              value.forEach((item, index) => {
                  // Append each integer item with the same key name
                  formData.append(`${key}[${index}]`, item);
              });
          } else 
          if (value instanceof File || value instanceof Blob) {
              // If the value is a File or Blob (e.g., an image), append it directly
              formData.append(key, value);
          } else {
              // For all other types (including strings and numbers), append normally
              formData.append(key, value);
          }
      }
  }

  return formData;
}

