import { useLocation } from "react-router-dom";
// // @mui
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Box,
} from "@mui/material";
// hooks
import useOffSetTop from "../../hooks/useOffSetTop";
import useResponsive from "../../hooks/useResponsive";
// utils
// import cssStyles from "../../utils/cssStyles";
// config
import { HEADER } from "../../config";
// components
// import Label from "../../components/Label";
// import Iconify from "src/components/Iconify";
// import LogoReverse from "src/components/LogoReverse";
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import SecondaryMenu from "./SecondaryMenu";
import SearchBar from "./SearchBar";

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  // height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: {
    // height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  // height: 44,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function ShopHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  // const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === "/";

  return (
    <AppBar component="nav" sx={{ boxShadow: 0, bgcolor: "primary.dark", px:2, py:2 }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            // ...cssStyles(theme).bgBlur(),
            // height: { md: HEADER.MAIN_DESKTOP_HEIGHT },
          }),
        }}
      >
        <Box
          component='div'
          sx={{
            // px: {md: 8, sm: 3, xs: 2},
            // py: 4,
            mb:1,
            width: '100%'
          }}
        >
       
          {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}


          {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}

        </Box>


      </ToolbarStyle>
      {isDesktop && <SecondaryMenu isMobile={!isDesktop} />}
      {!isDesktop && <SearchBar />}

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
