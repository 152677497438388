import { Autocomplete, Box, IconButton, InputBase, Paper } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import Iconify from "src/components/Iconify";
import { PATH_PAGE } from "src/routes/paths";
import { baseGet } from "src/utils/apiClient";
import { convertToUrlSafeWithPlus } from "src/utils/utils";

export default function SearchBar() {
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const {isLoading} = useQuery(
    ["search_products", search],
    () => baseGet(`product/search/${search}?&limit=30`),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        const _items = [{name: search}]
        setSuggestions(_items.concat(data.products))
      }
    }
  ) 

  const handleSearch = (e) => {
    e.preventDefault();

    navigate(PATH_PAGE.eCommerce.search(convertToUrlSafeWithPlus(search)))
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        // component="form"
        // onSubmit={handleSearch}
        sx={{
          borderRadius: 100,
          p: 0.5,
          display: "flex",
          justifyContent: 'space-between',
          alignItems: 'center',
          mx: 2,
        }}
      >
        <Autocomplete
          disablePortal
          freeSolo
          forcePopupIcon={false}
          // popupIcon={""}
          options={suggestions}
          loading={isLoading}
          sx={{ ml: 2, flex: 1 }}
          inputValue={search}
          onInputChange={(event, newVal) => {
            if((event?.type === "keydown" && event?.keyCode === 13 )|| event?.type === "click"){
              if( newVal !== "undefined"){
                setSearch(newVal)
                navigate(PATH_PAGE.eCommerce.search(convertToUrlSafeWithPlus(newVal)))
              } else {
                navigate(PATH_PAGE.eCommerce.search(convertToUrlSafeWithPlus(search)))
              }
            } 
            // else if(event?.type === "click"){
            //   console.log('clicked', event)
            //   // setSearch('')
            // }
          }}  
          getOptionLabel={(option) => option.name}
          renderInput={(params) => {
            const { InputLabelProps, InputProps, ...rest } = params;

            return (
              <InputBase
                {...InputProps}
                {...rest}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search YourBasket"
              />
            )
          }}
        />

        <IconButton
          // type="submit"
          onClick={handleSearch}
          sx={{ p: "10px", backgroundColor: "secondary.main" }}
          aria-label="search"
        >
          <Iconify
            icon={"eva:search-fill"}
            sx={{
              // ml: 1,
              width: 20,
              height: 20,
              color: "primary.dark",
            }}
          />
        </IconButton>
      </Paper>

    </Box>
  );
}
