// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),

  // vendor
  registerVendor: path(ROOTS_AUTH, '/vendor/register')
};

export const PATH_PAGE = {
  home:'/',
  homepage:'/new',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  // about: '/about-us',
  // contact: '/contact-us',
  // faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  privacyPolicy: '/privacy-policy',
  termsConditions: '/terms-conditions',
  returnPolicy: '/return-policy',
  components: '/components',
  eCommerce: {
    root: path('', '/'),
    shop: path('', '/shop'),
    search:(query) => path('', `/search?q=${query}`),
    category: (slug) => path('',`/category/${slug}`),
    section: (id) => path('',`/section/${id}`),
    list: path('', '/list'),
    wishlist: path('', '/wishlist'),
    checkout: path('', '/checkout'),
    new: path('', '/product/new'),
    view: (slug) => path('', `/product/${slug}`),
    edit: (name) => path('', `/product/${name}/edit`),
    demoEdit: path('', '/product/nike-blazer-low-77-vintage/edit'),
    demoView: path('', '/product/nike-air-force-1-ndestrukt'),
  },
  client: {
    root: path('', '/client'),
    account: path('', '/client/account'),
    myOrders: path('', '/client/orders'),
    orderDetails: (id) => path('', `/client/orders/${id}`),
  },
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  vendor: {
    products: path(ROOTS_DASHBOARD, '/vendor/products'),
    newProduct: path(ROOTS_DASHBOARD, '/vendor/product/new'),
    editProduct: (name) => path(ROOTS_DASHBOARD, `/vendor/product/${name}/edit`),
    profile: path(ROOTS_DASHBOARD, '/'),
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    order: path(ROOTS_DASHBOARD, '/order'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  homepage: {
    root: path(ROOTS_DASHBOARD, '/homepage'),
    sections: path(ROOTS_DASHBOARD, '/homepage/sections'),
    newSection: path(ROOTS_DASHBOARD, '/homepage/sections/new'),
    // viewSection: (id) => path(ROOTS_DASHBOARD, `/homepage/sections/${id}`),
    editSection: (id) => path(ROOTS_DASHBOARD, `/homepage/sections/edit/${id}`),
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/order'),
    list: path(ROOTS_DASHBOARD, '/order/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/order/view/${id}`),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, ''),
    shop: path(ROOTS_DASHBOARD, '/shop'),
    list: path(ROOTS_DASHBOARD, '/list'),
    checkout: path(ROOTS_DASHBOARD, '/checkout'),
    new: path(ROOTS_DASHBOARD, '/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/product/${name}`),
    orderDetails: (id) => path(ROOTS_DASHBOARD, `/order/${id}`),
    edit: (slug) => path(ROOTS_DASHBOARD, `/product/${slug}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = '';